import React, { useState, useEffect } from "react";
import System from "../../../models/system";
import SingleUserAuth from "./SingleUserAuth";
import MultiUserAuth from "./MultiUserAuth";
import {
  AUTH_TOKEN,
  AUTH_USER,
  AUTH_TIMESTAMP,
} from "../../../utils/constants";
import useLogo from "../../../hooks/useLogo";

export default function PasswordModal({ mode = "single" }) {
  const [customWebsiteLink, setCustomWebsiteLink] = useState("");
  const [loading, setLoading] = useState(true);
  const { logo } = useLogo();

  useEffect(() => {
    const fetchCustomWebsiteLink = async () => {
      const { websiteLink } = await System.fetchCustomWebsiteLink();
      setCustomWebsiteLink(websiteLink || "");
      setLoading(false);
    };

    fetchCustomWebsiteLink();
  }, []);

  return (
    <div className="fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-full flex flex-col md:flex-row items-center justify-center">
      <div className="absolute left-0 top-0 z-0 h-full w-full" />
      <div className="hidden md:flex md:w-1/2 md:h-full md:items-center md:justify-center">
        <div className="flex flex-row items-center justify-center">
          <img className="w-80" src={logo} alt="Company Logo" />
        </div>
        <div className="block footer absolute bottom-2">
          {loading ? (
            <p>...</p> // Optional loading state
          ) : customWebsiteLink ? (
            <a
              href={customWebsiteLink}
              className="text-[14px] outline-none normal-text underline"
            >
              Visit the website
            </a>
          ) : (
            <p className="text-[14px] outline-none text-black">
              &copy; IST {new Date().getFullYear()}
            </p>
          )}
        </div>
      </div>
      <div className="left-block flex flex-col items-center justify-center h-full w-full md:w-1/2 z-50 relative">
        {mode === "single" ? (
          <SingleUserAuth />
        ) : (
          <MultiUserAuth
            customWebsiteLink={customWebsiteLink}
            Linkloading={loading}
          />
        )}
      </div>
    </div>
  );
}

export function usePasswordModal(notry = false) {
  const [auth, setAuth] = useState({
    loading: true,
    requiresAuth: false,
    mode: "single",
  });

  useEffect(() => {
    async function checkAuthReq() {
      if (!window) return;

      // If the last validity check is still valid
      // we can skip the loading.
      if (!System.needsAuthCheck() && notry === false) {
        setAuth({
          loading: false,
          requiresAuth: false,
          mode: "multi",
        });
        return;
      }

      const settings = await System.keys();
      if (settings?.MultiUserMode) {
        const currentToken = window.localStorage.getItem(AUTH_TOKEN);
        if (!!currentToken) {
          const valid = notry ? false : await System.checkAuth(currentToken);
          if (!valid) {
            setAuth({
              loading: false,
              requiresAuth: true,
              mode: "multi",
            });
            window.localStorage.removeItem(AUTH_USER);
            window.localStorage.removeItem(AUTH_TOKEN);
            window.localStorage.removeItem(AUTH_TIMESTAMP);
            return;
          } else {
            setAuth({
              loading: false,
              requiresAuth: false,
              mode: "multi",
            });
            return;
          }
        } else {
          setAuth({
            loading: false,
            requiresAuth: true,
            mode: "multi",
          });
          return;
        }
      } else {
        const requiresAuth = settings?.RequiresAuth || false;
        if (!requiresAuth) {
          setAuth({
            loading: false,
            requiresAuth: false,
            mode: "single",
          });
          return;
        }

        const currentToken = window.localStorage.getItem(AUTH_TOKEN);
        if (!!currentToken) {
          const valid = notry ? false : await System.checkAuth(currentToken);
          if (!valid) {
            setAuth({
              loading: false,
              requiresAuth: true,
              mode: "single",
            });
            window.localStorage.removeItem(AUTH_TOKEN);
            window.localStorage.removeItem(AUTH_USER);
            window.localStorage.removeItem(AUTH_TIMESTAMP);
            return;
          } else {
            setAuth({
              loading: false,
              requiresAuth: false,
              mode: "single",
            });
            return;
          }
        } else {
          setAuth({
            loading: false,
            requiresAuth: true,
            mode: "single",
          });
          return;
        }
      }
    }
    checkAuthReq();
  }, []);

  return auth;
}
