import React, { useEffect, useState } from "react";
import System from "../../../models/system";
import { AUTH_TOKEN, AUTH_USER } from "../../../utils/constants";
import paths from "../../../utils/paths";
import showToast from "@/utils/toast";
import ModalWrapper from "@/components/ModalWrapper";
import { useModal } from "@/hooks/useModal";
import RecoveryCodeModal from "@/components/Modals/DisplayRecoveryCodeModal";

import { Globe } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import useLogo from "../../../hooks/useLogo";

const RecoveryForm = ({ onSubmit, setShowRecoveryForm }) => {
  const [username, setUsername] = useState("");
  const [recoveryCodeInputs, setRecoveryCodeInputs] = useState(
    Array(2).fill("")
  );

  const handleRecoveryCodeChange = (index, value) => {
    const updatedCodes = [...recoveryCodeInputs];
    updatedCodes[index] = value;
    setRecoveryCodeInputs(updatedCodes);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const recoveryCodes = recoveryCodeInputs.filter(
      (code) => code.trim() !== ""
    );
    onSubmit(username, recoveryCodes);
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col justify-center items-center relative rounded-2xl md:px-8 px-0 py-4 w-full md:w-fit mt-10 md:mt-0">
      <div className="flex items-center justify-between pt-11 pb-9 w-screen md:w-full md:px-12 px-6 text-white">
        <div className="flex flex-col text-center w-full">
          <h1 className="text-[25px] font-normal">Password Reset</h1>
          <small className="text-[15px] font-thin pb-2">
            Provide the necessary information below <br /> to reset your
            password.
          </small>
        </div>
      </div>
      <div className="md:px-12 px-6 space-y-6 flex h-full w-full login-form">
        <div className="w-full flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2 mb-4 form-group-">
            <label className="normal-text text-[11px] font-thin">
              Username
            </label>
            <input
              name="username"
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="text-white placeholder-white/20 text-sm rounded-md p-3 w-full h-[50px] md:w-[300px] md:h-[34px]"
              required
            />
          </div>

          <div className="flex flex-col gap-y-4 form-group-">
            <label className="normal-text text-[11px] font-thin">
              New password
            </label>
            {recoveryCodeInputs.map((code, index) => (
              <div key={index} className="w-full">
                <input type="text" name={`recoveryCode${index + 1}`} placeholder={`New password`} value={code} required
                  onChange={(e) =>
                    handleRecoveryCodeChange(index, e.target.value)
                  }
                  className="text-white placeholder-white/20 text-sm rounded-md p-3 w-full h-[50px] md:w-[300px] md:h-[34px]"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="login-form flex items-center md:p-6 md:px-0 px-6 mt-2 md:mt-0 space-x-2 border-gray-600 w-full flex-col gap-y-2">
        <button type="submit" className="submit-btn px-6 py-3 text-[#386d7e] rounded-3xl font-semibold w-[40%]">
          Reset Password
        </button>
        <div className="forgot-password pt-4 pb-5 text-white">
          <span onClick={() => setShowRecoveryForm(false)} className="text-[12px]">
            Back to login
          </span>
        </div>
      </div>
    </form>
  );
};

const ResetPasswordForm = ({ onSubmit }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(newPassword, confirmPassword);
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col justify-center items-center relative rounded-2xl md:modal-dark-bg md:shadow-[0_4px_14px_rgba(0,0,0,0.25)] md:px-12 px-0 py-4 w-full md:w-fit -mt-24 md:-mt-28">
      <div className="flex items-start justify-between pt-11 pb-9 w-screen md:w-full md:px-12 px-6">
        <div className="flex flex-col gap-y-4 w-full">
          <h3 className="text-4xl md:text-2xl font-bold normal-text text-center md:text-left">
            Reset Password
          </h3>
          <p className="text-sm normal-text md:text-left md:max-w-[300px] px-4 md:px-0 text-center">
            Enter your new password.
          </p>
        </div>
      </div>
      <div className="md:px-12 px-6 space-y-6 flex h-full w-full">
        <div className="w-full flex flex-col gap-y-4">
          <div>
            <input type="password" name="newPassword" placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required className="normal-text placeholder-white/20 text-sm rounded-md p-2.5 w-full h-[48px] md:w-[300px] md:h-[34px]"
            />
          </div>
          <div>
            <input type="password" name="confirmPassword" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required className="normal-text placeholder-white/20 text-sm rounded-md p-2.5 w-full h-[48px] md:w-[300px] md:h-[34px]"
            />
          </div>
        </div>
      </div>
      <div className="flex items-center md:p-12 md:px-0 px-6 mt-12 md:mt-0 space-x-2 border-gray-600 w-full flex-col gap-y-8">
        <button type="submit" className="primary-bg md:bg-transparent md:w-[300px] normal-text text-sm font-bold focus:ring-4 focus:outline-none rounded-md focus:z-10 w-full">
          Reset Password
        </button>
      </div>
    </form>
  );
};

export default function MultiUserAuth({ customWebsiteLink, Linkloading }) {
  const { t } = useTranslation();
  const { darkModeLogo } = useLogo();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [showRecoveryForm, setShowRecoveryForm] = useState(false);
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
  const [customAppName, setCustomAppName] = useState(null);
  const [supportEmail, setSupportEmail] = useState(false);
  const [customLoginText, setCustomLoginText] = useState(false);

  //Multi User
  const [isPublicUserMode, setIsPublicUserMode] = useState(false);

  const {
    isOpen: isRecoveryCodeModalOpen,
    openModal: openRecoveryCodeModal,
    closeModal: closeRecoveryCodeModal,
  } = useModal();

  useEffect(() => {
    const fetchSupportEmail = async () => {
      const supportEmail = await System.fetchSupportEmail();
      if (supportEmail) {
        setSupportEmail(supportEmail.email);
      }
    };
    fetchSupportEmail();
  }, []);

  const handleLogin = async (e) => {
    setError(null);
    setLoading(true);
    e.preventDefault();
    const data = {};
    const form = new FormData(e.target);
    for (var [key, value] of form.entries()) data[key] = value;
    const { valid, user, token, message, recoveryCodes } =
      await System.requestToken(data);
    if (valid && !!token && !!user) {
      setUser(user);
      setToken(token);

      window.localStorage.setItem(AUTH_USER, JSON.stringify(user));
      window.localStorage.setItem(AUTH_TOKEN, token);
      window.location = paths.home();
    } else {
      setError(message);
      setLoading(false);
    }
    setLoading(false);
  };

  const handlePublicModeLogin = async () => {
    const publicUsername = "publicuser";
    const publicPassword = "publicuser";

    setLoading(true);
    setError(null);

    const { valid, user, token, message } = await System.requestToken({
      username: publicUsername,
      password: publicPassword,
    });

    if (valid && !!token && !!user) {
      setUser(user);
      setToken(token);

      window.localStorage.setItem(AUTH_USER, JSON.stringify(user));
      window.localStorage.setItem(AUTH_TOKEN, token);
      window.location = paths.home();
    } else {
      setError(message);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleDownloadComplete = () => setDownloadComplete(true);
  const handleResetPassword = () => setShowRecoveryForm(true);
  const handleRecoverySubmit = async (username, recoveryCodes) => {
    const { success, resetToken, error } = await System.recoverAccount(
      username,
      recoveryCodes
    );

    if (success && resetToken) {
      window.localStorage.setItem("resetToken", resetToken);
      setShowRecoveryForm(false);
      setShowResetPasswordForm(true);
    } else {
      showToast(error, "error", { clear: true });
    }
  };

  const handleResetSubmit = async (newPassword, confirmPassword) => {
    const resetToken = window.localStorage.getItem("resetToken");

    if (resetToken) {
      const { success, error } = await System.resetPassword(
        resetToken,
        newPassword,
        confirmPassword
      );

      if (success) {
        window.localStorage.removeItem("resetToken");
        setShowResetPasswordForm(false);
        showToast(t("show-toast.password-reset"), "success", { clear: true });
      } else {
        showToast(error, "error", { clear: true });
      }
    } else {
      showToast(t("show-toast.invalid-reset"), "error", { clear: true });
    }
  };

  useEffect(() => {
    if (downloadComplete && user && token) {
      window.localStorage.setItem(AUTH_USER, JSON.stringify(user));
      window.localStorage.setItem(AUTH_TOKEN, token);
      window.location = paths.home();
    }
  }, [downloadComplete, user, token]);

  useEffect(() => {
    const fetchCustomAppName = async () => {
      const { appName } = await System.fetchCustomAppName();
      setCustomAppName(appName || "");
      setLoading(false);
    };
    fetchCustomAppName();
  }, []);

  useEffect(() => {
    const fetchCustomLoginText = async () => {
      const { paragraphText } = await System.fetchCustomParagraph();
      setCustomLoginText(paragraphText || t("login.password.contact"));
      setLoading(false);
    };
    fetchCustomLoginText();
  }, [t]);

  useEffect(() => {
    const fetchIsPublicUserMode = async () => {
      try {
        const publicUserModeEnabled = await System.isPublicUserMode();
        setIsPublicUserMode(publicUserModeEnabled);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchIsPublicUserMode();
  }, []);

  if (showRecoveryForm) {
    return (
      <RecoveryForm
        onSubmit={handleRecoverySubmit}
        setShowRecoveryForm={setShowRecoveryForm}
      />
    );
  }

  if (showResetPasswordForm)
    return <ResetPasswordForm onSubmit={handleResetSubmit} />;

  return (
    <>
      <div className="text-white flex flex-col items-center justify-center">
        <div className="pb-[10%] mob-avaible">
          <img src={darkModeLogo} alt="Company Logo" />
        </div>
        <h1 className="text-[18px] md:text-2xl lg:text-2xl">
          {t("login.multi-user.welcome")} {customAppName}
        </h1>
        <div className="w-[16vw] flex flex-col items-center justify-center">
          <small className="text-[15px] text-center font-thin pb-2 w-[80vw] md:w-full">
            {t("login.sign-in.start")}
          </small>
          <form className="login-form w-full flex flex-col items-center justify-center mt-4" onSubmit={handleLogin}>
            <div className="form-group w-[80vw] md:w-full">
              <input className="text-white text-sm rounded-md p-3 h-[35px]" name="username" type="text" placeholder={t("login.multi-user.placeholder-username")} required={true} autoComplete="off" />
            </div>
            <div className="form-group w-[80vw] md:w-full">
              <input className="text-white text-sm rounded-md p-3 h-[35px]" name="password" type="password" placeholder={t("login.multi-user.placeholder-password")} required={true} autoComplete="off" />
              {error && (
                <small className="text-red-400 text-[11px]">
                  {t("new-user.error")} {error}
                </small>
              )}
            </div>

            <div className="mt-3 flex flex-row items-center justify-center gap-8 pb-4 w-[80vw] md:w-full">
              <button className="submit-btn w-[80%] px-6 py-2 rounded-3xl font-semibold" type="submit">
                {t("login.multi-user.login")}
              </button>
            </div>
            {supportEmail && (
              <a href={`mailto:${supportEmail}`}>
                <div className="crossPlatform-login pt-3 w-[80vw] md:w-full text-[12px] forgot-password text-center">
                  <b>{t("login.password.forgot")}</b> <p>{customLoginText}</p>
                </div>
              </a>
            )}
          </form>
          {isPublicUserMode && (
            <div className="public-mode-block w-[80vw] md:w-full">
              <button className="public-mode-btn py-1 px-3 rounded-[6px] font-semibold flex flex-row justify-center items-center" type="submit" onClick={handlePublicModeLogin}>
                <Globe size={21} className="mr-1" />
                {t("login.publicMode")}
              </button>
            </div>
          )}
          <div className="block footer absolute bottom-2 md:hidden lg:hidden xl:hidden">
            {loading ? (
              <p>...</p>
            ) : customWebsiteLink ? (
              <a
                href={customWebsiteLink}
                className="text-[14px] outline-none text-white underline">
                Visit the website
              </a>
            ) : (
              <p className="text-[14px] outline-none text-white">&copy; IST {new Date().getFullYear()}</p>
            )}
          </div>
        </div>
      </div>

      <ModalWrapper isOpen={isRecoveryCodeModalOpen} noPortal={true}>
        <RecoveryCodeModal recoveryCodes={recoveryCodes} onDownloadComplete={handleDownloadComplete} onClose={closeRecoveryCodeModal}/>
      </ModalWrapper>
    </>
  );
}
